import React, { useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "./Newsletter.css"; // Import the CSS for placeholders

const Newsletter = (props) => {
  const { t } = useTranslation();
  let { BannerTitle, image1, image2, mainFontColor, subFontColor } =
    props[0].Info;

  const [email, setEmail] = useState("");
  const [status, setStatus] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/newsletter/subscribe`,
        {
          email: email,
        }
      );

      if (response.status === 200) {
        setStatus("Successfully subscribed!");
      } else {
        setStatus("Something went wrong. Please try again.");
      }
    } catch (error) {
      console.error("There was an error subscribing to the newsletter:", error);
      setStatus("Failed to subscribe. Please try again.");
    }
  };

  return (
    <section className="section-box overflow-visible mb-100 rtl">
      <div className="container mt-100">
        <div className="row">
          <div className="col-lg-10 mx-auto">
            <div className="bg-2 box-newsletter position-relative">
              <div className="row">
                <div className="col-lg-5 col-md-7">
                  <span
                    className="text-body-capitalized color-gray-500 text-uppercase"
                    style={{ color: subFontColor }}
                  >
                    {t("newsletter")}
                  </span>
                  <h4
                    className="text-heading-2 mb-10 mt-10"
                    style={{ color: mainFontColor }}
                  >
                    {t("subscribeOurNewsletter")}
                  </h4>
                  <p
                    className="text-body-text color-gray-500"
                    style={{ color: subFontColor }}
                  >
                    {BannerTitle}
                  </p>
                  <Link to="/page-terms">{t("termAndConditions")}</Link>
                  <div className="box-form-newsletter mt-30">
                    <form className="form-newsletter" onSubmit={handleSubmit}>
                      <input
                        className="input-newsletter rtl"
                        placeholder={t("enterYourEmail")}
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                      />
                      <button type="submit" className="btn btn-send"></button>
                    </form>
                  </div>
                  <br />
                  {status && (
                    <p style={{ fontSize: "18px", color: "white" }}>{status}</p>
                  )}
                </div>
                <div className="col-lg-7 col-md-5 mt-30 mt-lg-0 mt-md-30 mt-sm-30 position-relative text-end">
                  <div className="block-chart shape-1">
                    <ImageWithPlaceholder
                      src={`${process.env.REACT_APP_API_URL}/${image2}`}
                      alt={BannerTitle}
                    />
                  </div>
                  <div className="img-responsive img-newsletter">
                    <ImageWithPlaceholder
                      src={`${process.env.REACT_APP_API_URL}/${image1}`}
                      alt={BannerTitle}
                    />
                  </div>
                  {/* <img
                    className="img-responsive img-newsletter"
                    src={`${process.env.REACT_APP_API_URL}/${image1}`}
                    alt={BannerTitle}
                  /> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

// Image component with placeholder effect
const ImageWithPlaceholder = ({ src, alt, className = "" }) => {
  const [isLoaded, setIsLoaded] = useState(false);

  return (
    <div className="newsletter-image-wrapper">
      {!isLoaded && <div className="newsletter-image"></div>}
      <img
        src={src}
        alt={alt}
        className={`actual-image ${className} ${
          isLoaded ? "loaded" : "hidden"
        }`}
        onLoad={() => setIsLoaded(true)}
      />
    </div>
  );
};

export default Newsletter;
