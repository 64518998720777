import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import "./MiddleSection1.css";
import { processImage } from "../DesginComponent/process";

const MiddleSection2 = (props) => {
  const { t } = useTranslation();
  let {
    sectionTitle,
    sectionDescription,
    image,
    title1,
    description1,
    title2,
    description2,
    title3,
    description3,
    title4,
    description4,
  } = props[0].Info;

  const [imageSrc, setImageSrc] = useState(null);
  useEffect(() => {
    const fetchImages = async () => {
      try {
        const main = await processImage(image);
        setImageSrc(main);
      } catch (err) {
        console.error("Error processing images:", err);
      }
    };
    fetchImages();
  }, [image]);

  return (
    <section className="section-box rtl">
      <div className="container mt-120">
        <div className="row">
          <div className="col-lg-6 col-sm-12 block-img-we-do">
            {imageSrc && (
              <ImageWithPlaceholder
                className="bdrd-16 img-responsive"
                src={`${process.env.REACT_APP_API_URL}/${image}`}
                alt={props.pageTitle}
              />
            )}
          </div>
          <div className="col-lg-6 col-sm-12 block-we-do">
            {/* <span className="tag-1 bg-6 color-green-900">
              {t('whatWeDo2')}
            </span> */}
            <h3 className="text-heading-1 mt-30">{sectionTitle}</h3>
            <p className="text-body-lead-large color-gray-600 mt-30">
              {sectionDescription}
            </p>
            <div className="line-bd-green mt-50" />
            <div className="row">
              <div className="col-lg-6 col-sm-6 col-12 mt-50">
                <h4 className="text-heading-6 icon-leaf">{title1}</h4>
                <p className="text-body-excerpt color-gray-600 mt-15">
                  {description1}
                </p>
              </div>
              <div className="col-lg-6 col-sm-6 col-12 mt-50">
                <h4 className="text-heading-6 icon-leaf">{title2}</h4>
                <p className="text-body-excerpt color-gray-600 mt-15">
                  {description2}
                </p>
              </div>
              <div className="col-lg-6 col-sm-6 col-12 mt-50">
                <h4 className="text-heading-6 icon-leaf">{title3}</h4>
                <p className="text-body-excerpt color-gray-600 mt-15">
                  {description3}
                </p>
              </div>
              <div className="col-lg-6 col-sm-6 col-12 mt-50">
                <h4 className="text-heading-6 icon-leaf">{title4}</h4>
                <p className="text-body-excerpt color-gray-600 mt-15">
                  {description4}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

const ImageWithPlaceholder = ({ src, alt, className = "", style = {} }) => {
  const [isLoaded, setIsLoaded] = useState(false);

  return (
    <div className="image-wrapper" style={style}>
      {!isLoaded && <div className="image-placeholder"></div>}
      <img
        src={src}
        alt={alt}
        className={`placeholder-image ${className} ${
          isLoaded ? "placeholder-loaded" : "placeholder-hidden"
        }`}
        onLoad={() => setIsLoaded(true)}
      />
    </div>
  );
};

export default MiddleSection2;
