import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import LazyLoad from "react-lazyload";
import { processImage } from "../../DesginComponent/process";

const Banner1 = (props) => {
  let {
    banner1Title,
    banner1Desc,
    banner1Img,
    mainFontColor,
    subFontColor,
    linkTitle1,
    link1,
    linkTitle2,
    link2,
  } = props[0].Info;
  const [banner1ImgSrc, setBanner1ImgSrc] = useState(null);
  useEffect(() => {
    const fetchImages = async () => {
      try {
        const main = await processImage(banner1Img);

        setBanner1ImgSrc(main);
      } catch (err) {
        console.error("Error processing images:", err);
      }
    };

    fetchImages();
  }, [banner1Img]); // Dependencies

  return (
    <section className="section-box rtl">
      <div className="banner-hero banner-1">
        <div className="container">
          <div className="row">
            <div className="col-lg-7">
              <h1 className="text-display-2" style={{ color: mainFontColor }}>
                {/* We are
                            <span className="color-green-900">awesome team</span> */}
                {banner1Title}
              </h1>
              <p
                className="text-body-lead-large color-gray-500 mt-40 pr-40"
                style={{ color: subFontColor }}
              >
                {banner1Desc}
              </p>
              {/* <div className="mt-40">
                            <Link href={link1} legacyBehavior><a className="btn btn-black icon-arrow-right-white">{linkTitle1}</a></Link>
                            <Link href={link2} legacyBehavior><a className="btn btn-link icon-arrow-right color-gray-900 text-heading-6">{linkTitle2}</a></Link>
                        </div> */}
            </div>
            <div className="col-lg-5 d-none d-lg-block">
              <div className="banner-imgs">
                {/* <div className="video-block shape-1"> */}
                {/* <a className="popup-youtube btn-play-video" ></a> */}
                {/* onClick={openModal} */}
                {/* <span className="text-heading-4">Watch intro video</span> */}

                {/* </div> */}

                {banner1ImgSrc && (
                  <ImageWithPlaceholder
                    className="img-responsive shape-1"
                    alt="Al Noblaa"
                    src={banner1ImgSrc}
                    style={{ paddingTop: "20px" }}
                  />
                )}
                {/* assets/imgs/page/homepage1/banner.png */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
const ImageWithPlaceholder = ({ src, alt, className = "", style = {} }) => {
  const [isLoaded, setIsLoaded] = useState(false);
  return (
    <img
      src={src}
      alt={alt}
      className={`placeholder-image ${className} ${
        isLoaded ? "placeholder-loaded" : "placeholder-hidden"
      }`}
      onLoad={() => setIsLoaded(true)}
    />
  );
};

export default Banner1;
