import React, { useState } from "react";
import LazyLoad from "react-lazyload";
import { useTranslation } from "react-i18next";
import "./BlogList.css"; // Import the CSS for the placeholder effect

const BlogList = (props) => {
  const { t } = useTranslation();
  const {
    Data: categoriesOrProducts,
    Info: { singleBlogLink, PrimaryColor },
  } = props[0];

  const isCategoryData =
    Array.isArray(categoriesOrProducts) && categoriesOrProducts[0]?.Products;

  const [selectedCategoryId, setSelectedCategoryId] = useState(
    isCategoryData ? categoriesOrProducts[0]?.Id : null
  );

  const truncateTitle = (title, maxLength) => {
    return title.length > maxLength ? title.slice(0, maxLength) + "..." : title;
  };

  const getProducts = () => {
    if (isCategoryData && selectedCategoryId) {
      const selectedCategory = categoriesOrProducts.find(
        (category) => category.Id === selectedCategoryId
      );
      return selectedCategory ? selectedCategory.Products : [];
    } else if (!isCategoryData) {
      return categoriesOrProducts;
    }
    return [];
  };

  const productsToDisplay = getProducts();

  const currentLanguage = localStorage.getItem("i18nextLng") || "en";
  return (
    <div className="container mt-90 rtl">
      {/* Tabs for Categories */}
      {isCategoryData && (
        <div className="category-tabs mb-4 d-flex flex-wrap">
          {categoriesOrProducts.map((category) => (
            <button
              style={{ backgroundColor: PrimaryColor }}
              key={category.Id}
              className={`category-tab ${
                selectedCategoryId === category.Id ? "active" : ""
              }`}
              onClick={() => setSelectedCategoryId(category.Id)}
            >
              {truncateTitle(category.TitleEn, 30)}
            </button>
          ))}
        </div>
      )}

      {/* Product Grid */}
      <div
        className={`row ${currentLanguage === "ar" ? "blog-ltr" : "blog-ltr"}`}
      >
        {productsToDisplay.length > 0 ? (
          productsToDisplay.map((item) => (
            <div
              className={`col-lg-4 col-sm-12 ${
                currentLanguage === "ar" ? "text-right" : "text-left"
              } pr-30 mb-50`}
              key={item.Id}
            >
              <div className="card-grid-style-4">
                <a href={`/${singleBlogLink}/${item.Id}`} legacyBehavior>
                  <a className="text-heading-4">
                    {truncateTitle(
                      currentLanguage === "en" ? item.TitleEn : item.TitleAr,
                      60
                    )}
                  </a>
                </a>
                <div className="grid-4-img">
                  <LazyLoad>
                    <ImageWithPlaceholder
                      src={`${process.env.REACT_APP_API_URL}/uploads/${item.Thumbnail}`}
                      alt={truncateTitle(
                        currentLanguage === "en" ? item.TitleEn : item.TitleAr,
                        60
                      )}
                    />
                  </LazyLoad>
                </div>
              </div>
            </div>
          ))
        ) : (
          <div className="col-12 text-center">
            <p>{t("noProducts")}</p>
          </div>
        )}
      </div>
    </div>
  );
};

// Image component with placeholder effect
const ImageWithPlaceholder = ({ src, alt }) => {
  const [isLoaded, setIsLoaded] = useState(false);

  return (
    <div className="blog-list-image-wrapper">
      {!isLoaded && <div className="blog-list-image"></div>}
      <img
        src={src}
        alt={alt}
        className={`actual-image ${isLoaded ? "loaded" : "hidden"}`}
        onLoad={() => setIsLoaded(true)}
      />
    </div>
  );
};

export default BlogList;
