import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import "./Banner2.css";
import { processImage } from "../../DesginComponent/process";

const Banner2 = (props) => {
  const { t } = useTranslation();

  let {
    banner2Title,
    banner2Desc,
    mainImage,
    smallImage1,
    smallImage2,
    mainFontColor,
    subFontColor,
    smallTitle1,
    smallTitleValue1,
    smallTitle2,
    smallTitleValue2,
    smallTitle3,
    smallTitleValue3,
  } = props[0].Info;

  // States to store processed image URLs
  const [mainImageSrc, setMainImageSrc] = useState(null);
  const [smallImage1Src, setSmallImage1Src] = useState(null);
  const [smallImage2Src, setSmallImage2Src] = useState(null);

  // UseEffect to process images asynchronously
  useEffect(() => {
    const fetchImages = async () => {
      try {
        const main = await processImage(mainImage);
        const small1 = await processImage(smallImage1);
        const small2 = await processImage(smallImage2);

        setMainImageSrc(main);
        setSmallImage1Src(small1);
        setSmallImage2Src(small2);
      } catch (err) {
        console.error("Error processing images:", err);
      }
    };

    fetchImages();
  }, [mainImage, smallImage1, smallImage2]); // Dependencies

  return (
    <section className="section-box rtl">
      <div className="banner-hero banner-2">
        <div className="container">
          <div className="row">
            <div className="col-lg-7 mt-50" style={{ paddingRight: "70px" }}>
              <h1
                className="text-display-3 mt-30"
                style={{ color: mainFontColor }}
              >
                {banner2Title}
              </h1>
              <p
                className="text-body-lead-large color-gray-500 mt-40 pr-40"
                style={{ color: subFontColor }}
              >
                {banner2Desc}
              </p>
              <div className="mt-50">
                <div className="row">
                  <div className="col-lg-3 col-sm-4 col-4">
                    <h3 className="text-heading-4 color-gray-900 mb-15">
                      <span className="count">{smallTitleValue1}</span>
                    </h3>
                    <p
                      className="text-body-text-md color-gray-500"
                      style={{ color: subFontColor }}
                    >
                      {smallTitle1}
                    </p>
                  </div>
                  <div className="col-lg-3 col-sm-4 col-4">
                    <h3 className="text-heading-4 color-gray-900 mb-15">
                      <span className="count">{smallTitleValue2}</span>
                    </h3>
                    <p
                      className="text-body-text-md color-gray-500"
                      style={{ color: subFontColor }}
                    >
                      {smallTitle2}
                    </p>
                  </div>
                  <div className="col-lg-3 col-sm-4 col-4">
                    <h3 className="text-heading-4 color-gray-900 mb-15">
                      <span className="count">{smallTitleValue3}</span>
                    </h3>
                    <p
                      className="text-body-text-md color-gray-500"
                      style={{ color: subFontColor }}
                    >
                      {smallTitle3}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-5 d-none d-lg-block">
              <div className="banner-imgs" style={{ paddingLeft: "20px" }}>
                <div
                  className="block-1 shape-2"
                  style={{ marginBottom: "20px" }}
                >
                  {smallImage1Src && (
                    <ImageWithPlaceholder
                      src={smallImage1Src}
                      alt="Al Noblaa"
                    />
                  )}
                </div>
                <div
                  className="block-2 shape-3"
                  style={{ marginBottom: "20px" }}
                >
                  {smallImage2Src && (
                    <ImageWithPlaceholder
                      src={smallImage2Src}
                      alt="Al Noblaa"
                    />
                  )}
                </div>
                {mainImageSrc && (
                  <ImageWithPlaceholder
                    className="shape-1"
                    alt="Al Noblaa"
                    src={mainImageSrc}
                    style={{ paddingTop: "20px" }}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

const ImageWithPlaceholder = ({ src, alt, className = "", style = {} }) => {
  const [isLoaded, setIsLoaded] = useState(false);

  return (
    <div className="image-wrapper" style={style}>
      {!isLoaded && <div className="image-placeholder"></div>}
      <img
        src={src}
        alt={alt}
        className={`placeholder-image ${className} ${
          isLoaded ? "placeholder-loaded" : "placeholder-hidden"
        }`}
        onLoad={() => setIsLoaded(true)}
      />
    </div>
  );
};

export default Banner2;
