import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { processImage } from "../../DesginComponent/process";

const Banner5 = (props) => {
  const { t } = useTranslation(); // Initialize translation hook

  let {
    bannerTitle,
    bannerDesc,
    mainImage,
    mainFontColor,
    subFontColor,
    smallTitle1,
    smallTitleValue1,
    smallTitle2,
    smallTitleValue2,
    smallTitle3,
    smallTitleValue3,
    link1Title,
    link1,
    link2Title,
    link2,
  } = props[0].Info;

  const [mainImageSrc, setMainImageSrc] = useState(null);
  useEffect(() => {
    const fetchImages = async () => {
      try {
        const main = await processImage(mainImage);
        setMainImageSrc(main);
      } catch (err) {
        console.error("Error processing images:", err);
      }
    };

    fetchImages();
  }, [mainImage]); // Dependencies

  return (
    <section className="section-box rtl">
      <div className="banner-hero banner-4">
        <div className="container">
          <div className="row">
            <div className="col-lg-7" style={{ paddingRight: "70px" }}>
              <h1 className="text-display-2" style={{ color: mainFontColor }}>
                {bannerTitle}
              </h1>
              <p
                className="text-body-lead-large mt-30 pr-40"
                style={{ color: subFontColor }}
              >
                {bannerDesc}
              </p>
              {/* <div className="mt-40">
                <Link href={link1} legacyBehavior>
                  <a className="btn btn-pink icon-arrow-right-white text-heading-6">
                    {link1Title}
                  </a>
                </Link>
                <Link href={link2} legacyBehavior>
                  <a className="btn btn-link color-white text-heading-6 btn-link-inter">
                    {link2Title}
                  </a>
                </Link>
              </div> */}
              <div className="mt-60">
                <div className="row">
                  <div className="col-lg-3 col-sm-4 col-4">
                    <h3
                      className="text-heading-2 mb-15"
                      style={{ color: subFontColor }}
                    >
                      {smallTitleValue1}
                    </h3>
                    <p
                      className="text-body-normal"
                      style={{ color: mainFontColor }}
                    >
                      {smallTitle1}
                    </p>
                  </div>
                  <div className="col-lg-3 col-sm-4 col-4">
                    <h3
                      className="text-heading-2 mb-15"
                      style={{ color: subFontColor }}
                    >
                      {smallTitleValue2}
                    </h3>
                    <p
                      className="text-body-normal"
                      style={{ color: mainFontColor }}
                    >
                      {smallTitle2}
                    </p>
                  </div>
                  <div className="col-lg-3 col-sm-4 col-4">
                    <h3
                      className="text-heading-2 mb-15"
                      style={{ color: subFontColor }}
                    >
                      {smallTitleValue3}
                    </h3>
                    <p
                      className="text-body-normal"
                      style={{ color: mainFontColor }}
                    >
                      {smallTitle3}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-5 d-none d-lg-block">
              <div className="banner-imgs">
                {mainImageSrc && (
                  <ImageWithPlaceholder
                    className="img-responsive shape-2"
                    alt="Al Noblaa"
                    src={mainImageSrc}
                    style={{ paddingTop: "20px" }}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

const ImageWithPlaceholder = ({ src, alt, className = "", style = {} }) => {
  const [isLoaded, setIsLoaded] = useState(false);

  return (
    <img
      src={src}
      alt={alt}
      className={`placeholder-image ${className} ${
        isLoaded ? "placeholder-loaded" : "placeholder-hidden"
      }`}
      onLoad={() => setIsLoaded(true)}
    />
  );
};
export default Banner5;
