export const processImage = async (fileName, width = 300, height = 300) => {
  try {
    // Call the process API
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/api/Resize?fileName=${encodeURIComponent(
        fileName
      )}&width=${width}&height=${height}`
    );

    if (!response.ok) {
      throw new Error(`Error processing image: ${response.statusText}`);
    }

    // Convert the response to a blob
    const blob = await response.blob();

    // Create and return a blob URL for the processed image
    return URL.createObjectURL(blob);
  } catch (err) {
    console.error("Failed to process the image:", err);
    throw err; // Allow the caller to handle the error
  }
};
